<template>
    <div class="ml-10 mr-10">
        <TitleBar>
            {{ $t("menu.expense_reports") }}
        </TitleBar>

        <List
            :fields="['expense_report_number', 'description', {key:'amount', sortable: false}, 'status', 'created_at']"
              api-key="expense_reports"
              api-url="/expenses/expense-reports"></List>

    </div>
</template>

<script>

import List from "../../components/List";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "Dashboard",
    components: {TitleBar, List},
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.expense_reports"),
                to: "/expenses/expense-reports",
                exact: true
            }
        ]);
    }
};
</script>

<style scoped>

</style>